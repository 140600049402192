<app-navbar></app-navbar>

<section class="parallax-bg fixed-bg xxl-screen pt-0 pb-0" id="home">
    <div class="hero-text-wrap xxl-screen ">
        <div class="hero-text">
            <div class="text-content-slider">
                <owl-carousel-o [options]="customOptions" class="owl-btn-center-lr">
                    <ng-template carouselSlide *ngFor="let slide of slideStore">
                        <div class="container">
                            <div class="row d-flex justify-content-center">
                                <div class="col-md-8">
                                    <div class="all-padding-50 text-center white-color">
                                        <h2 class="raleway-font font-100">{{slide.introtext}}</h2>
                                        <h1 class="font-700 text-uppercase font-100px line-height-90 xs-font-40px xs-line-height-50 sm-font-60px sm-line-height-60">{{slide.title}}</h1>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
    <video autoplay [muted]="isMuted" loop class="html5-video">
        <source src="assets/videos/explore.mp4" type="video/mp4">
        <source src="assets/videos/explore.webm" type="video/webm">
    </video>
</section>

<section class="white-bg xs-pb-100" id="features">
    <div class="container">
        <div class="row">
            <div *ngFor="let item of features;" class="col-md-4 col-sm-4 col-xs-12 mb-30 hover-effect">
                <div [ngClass]="item.featureBox">
                    <i [ngClass]="item.icon"></i>
                    <h4 class="mt-0 font-600">{{item.title}}</h4>
                    <p class="font-400">{{item.desc}}</p>
                </div>
            </div>
        </div>
        
    </div>
</section>

<section class="default-bg pt-80 pb-80">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title mb-50 white-color text-center">
                    <h1 class="raleway-font remove-margin font-50px font-300">Como lo hacemos</h1>
                </div>
            </div>
        </div>
        <div class="row our-process-style-02">
            <div *ngFor="let step of steps" class="col-md-3 col-sm-6 col-xs-12 line xs-mb-30 sm-mb-30">
                <div class="icon-wrap white-bg">
                    <div class="icon">
                        <i [ngClass]="step.icon"></i>
                    </div>
                </div>
                <div class="text-center white-color">
                    <h4 class="font-500">{{step.title}}</h4>
                    <p class="mb-0">{{step.desc}}</p>
                </div>
            </div>
        </div>
    </div>
</section>

     <section class="white-bg pb-80" id="servicios">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <!-- <h2 class="raleway-font default-color">What We Offer</h2> -->
                    <h1 class="raleway-font">Nuestros Servicios</h1>
                </div>
                <p>Acompañamos a las empresas en su transformación digital con servicios especializados en Cloud Computing, 
                    optimización y gestión en plataformas como AWS, Azure, Google Cloud y Oracle. Nuestras soluciones están diseñadas 
                    para agilizar tus operaciones, mejorar la escalabilidad y reducir el time to market, 
                    garantizando eficiencia en cada etapa del camino.</p>
            </div>
        </div>

        <div class="row mt-50">
            <div *ngFor="let service of services" class="col-md-4 col-sm-4 col-xs-12 mb-30 feature-box text-center hover-effect">
                <div class="default-bg-icon mb-20">
                    <i [ngClass]="service.icon"></i>
                </div>
                <h5 class="mt-10">{{service.title}}</h5>
                <p class="font-400">{{service.desc}}</p>
            </div>
        </div>

    </div>
</section>

<section class="white-bg" id="nosotros">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-sm-23 text-left sm-mt-0 d-flex align-items-center">
                <div class="d-block pl-30 sm-pl-0">
                    <div class="section-title mb-50">
                        <h2 class="raleway-font default-color mt-0">K2W IT SOLUTIONS</h2>
                        <h1 class="raleway-font mt-30 font-50px font-300">Nuestra Diferencia <span class="type-it default-color">{{ displayedText }}</span></h1>
                    </div>
                    <p>Somos una empresa mexicana joven dedicada a crear experiencias digitales innovadoras, 
                        especializándonos en soluciones tecnológicas basadas en Cloud Computing. Con un equipo altamente capacitado, aplicamos nuestro conocimiento avanzado en el desarrollo e implementación de aplicaciones y servicios en la nube, 
                        optimizando cada paso hacia la transformación digital.</p>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="responsive-screen mt-0">
                    <img src="assets/images/laptop.png" class="img-responsive" alt="laptop">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="grey-bg" id="portfolio">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <h2 class="raleway-font default-color">Nuestra Galería de Proyectos</h2>
                    <h1 class="raleway-font">Soluciones Innovadoras para su Negocio</h1>
                </div>
                <p>En K2W IT Solutions, nos especializamos en ofrecer soluciones tecnológicas personalizadas que transforman y 
                    optimizan los procesos de su empresa. Desde aplicaciones en la nube hasta consultoría en IT, 
                    cada proyecto está diseñado para brindar valor y eficiencia. Descubre cómo hemos ayudado a nuestros clientes a 
                    alcanzar sus objetivos a 
                    través de nuestra experiencia y compromiso con la calidad.</p>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row mt-50">
            <div *ngFor="let portfolio of portfolios" class="col-md-3 col-sm-6 portfolio with-spacing">
                <div class="portfolio-item border-white-15">
                    <a href="javascript:void(0)">
                        <img src="assets/images/portfolio/grid/{{portfolio.img}}" alt="">
                        <div class="portfolio-info default-bg">
                            <div class="centrize">
                                <div class="v-center white-color">
                                    <h3>{{portfolio.title}}</h3>
                                    <p>{{portfolio.subTitle}}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="parallax-bg fixed-bg" style="background-image: url('assets/images/background/parallax-bg-2.jpg')">
    <div class="overlay-bg"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center parallax-content height-400px">
                <div class="center-layout">
                    <div class="v-align-middle">
                        <h1 class="font-400 white-color raleway-font xs-font-40px">Aprovechamos las mejores tecnologías del mercado para 
                            ofrecer soluciones de vanguardia en la nube.</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="grey-bg pt-50 pb-80">
    <div class="container">
        <div class="row">
            <div class="client-slider">
                <owl-carousel-o [options]="customOptionsFour" class="owl-btn-center-lr">
                    <ng-template carouselSlide *ngFor="let slide of slideStoreFour">
                        <div class="client-logo">
                            <img class="img-responsive" src="{{slide.image}}" alt="01" />
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>

    </div>
</div>

<section class="white-bg transition-none" id="contact">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <h2 class="raleway-font default-color">Solo mantente en contacto</h2>
                    <h1 class="raleway-font">Contacta con nosotros ahora</h1>
                </div>
                <p>Estamos listos para escucharte.</p>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row mt-70">
            <div class="col-md-6 col-sm-6 col-xs-12 xs-mb-50">
                
                <div class="row mt-20">
                    
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="section-title">
                            <h2 class="raleway-font default-color">K2W IT Solutions México</h2>
                            <p class="mt-30">Homero 527 Int 701, Col. Polanco, CP 11560, Miguel Hidalgo
                            </p>
                        </div>
                    </div>                  
                    
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="section-title">
                            <h2 class="raleway-font default-color">Office Numbers</h2>
                            <p class="mb-0 mt-30">
                                <a href="https://wa.me/525523244356?text=Hola,%20me%20gustaría%20saber%20más%20sobre%20sus%20servicios" target="_blank" style="color: #151615; text-decoration: none;">
                                    <span class="icon-whatsapp"></span>+52 552 324 4356
                            </a>
                            </p>
                            <p class="mb-0"><a href="https://wa.me/525531110995?text=Hola,%20me%20gustaría%20saber%20más%20sobre%20sus%20servicios" target="_blank" style="color: #151615; text-decoration: none;">
                                <span class="icon-whatsapp"></span>+52 553 111 0995
                                </a>
                            </p>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="section-title">
                            <h2 class="raleway-font default-color">Email</h2>
                            <p class="mb-0 mt-30">contacto&#64;k2w.com.mx</p>
                        </div>
                    </div>
                </div>
                                
                <div class="row mt-20">
                    <div class="col-md-12 social-icons-style-01">
                        <ul class="md-icon left-icon">
                            <li *ngFor="let social of socialMedia">
                                <a [ngClass]="social.iconClass" [href]="social.link || 'javascript:void(0)'" 
                                   [attr.target]="social.link ? '_blank' : null">
                                    <i [ngClass]="social.icon"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <form (submit)="sendEmail($event)" class="contact-form-style-01" #contactForm="ngForm">
                    <div class="row">
                        <div class="col-md-6 col-sm-6">
                            <div class="form-group">
                                <label class="visually-hidden" for="user_name">Nombre</label>
                                <input type="text" minlength="2" maxlength="15"
                                    [class.is-invalid]="userName.invalid && userName.touched" name="user_name"
                                    class="md-input" id="name" placeholder="Name *" [(ngModel)]="formData.user_name"
                                    #userName="ngModel" required>
                                <div *ngIf="userName.invalid && userName.touched" class="invalid-feedback mb-10">
                                    Nombre es requerido.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <div class="mb-3">
                                <label class="visually-hidden" for="user_email">Email</label>
                                <input type="email" minlength="2" maxlength="35"
                                    [class.is-invalid]="userEmail.invalid && userEmail.touched" name="user_email"
                                    class="md-input" id="email" placeholder="Email *" [(ngModel)]="formData.user_email"
                                    #userEmail="ngModel" required email>
                                <div *ngIf="userEmail.invalid && userEmail.touched" class="invalid-feedback mb-10">
                                    Se requiere un correo electrónico válido.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12">
                            <div class="mb-3">
                                <label class="visually-hidden" for="message">Mensaje</label>
                                <textarea name="message" [class.is-invalid]="userMessage.invalid && userMessage.touched"
                                    class="md-textarea" id="message" rows="7" placeholder="Project Details"
                                    [(ngModel)]="formData.message" #userMessage="ngModel" required></textarea>
                                <div *ngIf="userMessage.invalid && userMessage.touched" class="invalid-feedback mb-10">
                                    Se requieren detalles del proyecto.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12">
                            <div class="text-left mt-20">
                                <button type="submit" name="submit"
                                    class="btn btn-outline btn-md btn-square btn-animate remove-margin"
                                    [disabled]="contactForm.invalid" value="Send">
                                    <span>Enviar Mensaje <i class="ion-android-arrow-forward"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</section>