import { Component, HostListener } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import emailjs, { type EmailJSResponseStatus } from '@emailjs/browser';


@Component({
  selector: 'app-home-seven',
  templateUrl: './home-seven.component.html',
  styleUrl: './home-seven.component.css'
})
export class HomeSevenComponent {

  // Video Player
  isMuted: boolean = true; // Start with the video muted

  toggleMute(): void {
    this.isMuted = !this.isMuted;
  }

  customOptions: OwlOptions = {
    loop: true,
    dots: true,
    margin:0,
    nav:false,
    autoplay:true,
    autoplaySpeed: 2000,
    navSpeed: 2000,
    items: 1,
    navText: ['<i class="icofont icofont-arrow-left"></i>', '<i class="icofont icofont-arrow-right"></i>'],
  }

  // Type it
  phrases: string[] = ['Garantizar la continuidad operativa en la nube.', 'Optimizar la infraestructura cloud para mayor agilidad y eficiencia.', 'Eliminar prácticas y tecnologías obsoletas, impulsando la modernización.', 'Escalabilidad modular.', 'Soporte Proactivo.', 'Despliegues automatizados con DevOps y DevSecOps.']; 
  displayedText: string = ''; 
  currentPhraseIndex: number = 0; 
  currentIndex: number = 0; 
  typingSpeed: number = 100; 
  delayBetweenPhrases: number = 1500; 

  slideStore = [
    {
      introtext: "Acompañamos tu camino hacia la nube",
      title: "Cloud Computing",   
    },
    {
      introtext: "Potenciamos la eficiencia y la innovación",
      title: "Transformación Digital",
    },
    {
      introtext: "Innovación Tecnológica para tu negocio",
      title: "Optimización en la Nube",
    },
    {
      introtext: "Mejora continua en la operación",
      title: "Eficiencia Operativa",
    },
    {
      introtext: "Agilidad para el éxito empresarial",
      title: "Agilidad Empresarial",
    }

  ]

  ngOnInit(): void {
    this.startTyping();
  }

  startTyping(): void {
    this.typePhrase();
  }

  typePhrase(): void {
    const phrase = this.phrases[this.currentPhraseIndex];

    const typingInterval = setInterval(() => {
      if (this.currentIndex < phrase.length) {
        this.displayedText += phrase.charAt(this.currentIndex);
        this.currentIndex++;
      } else {
        clearInterval(typingInterval);
        setTimeout(() => {
          this.startDeleting();
        }, this.delayBetweenPhrases); // Delay before starting to delete
      }
    }, this.typingSpeed);
  }

  startDeleting(): void {
    const deletingInterval = setInterval(() => {
      if (this.currentIndex > 0) {
        this.displayedText = this.displayedText.slice(0, -1);
        this.currentIndex--;
      } else {
        clearInterval(deletingInterval);
        this.currentPhraseIndex = (this.currentPhraseIndex + 1) % this.phrases.length;
        this.currentIndex = 0;
        setTimeout(() => {
          this.typePhrase();
        }, this.delayBetweenPhrases); // Delay before starting to type the next phrase
      }
    }, this.typingSpeed / 2); // Faster deleting speed
  }

  // Features
  features =[
    {
      featureBox: "feature-box text-center xs-mb-30 feature-box-gradient center-feature white-color border-radius-25",
      icon: "icon-cloud font-40px",
      title: "Cloud computing",
      desc:"Implementaciones de nube adaptadas a las necesidades específicas de tu negocio.",
    },
    {
      featureBox: "feature-box text-center xs-mb-30 feature-box-gradient-six center-feature white-color border-radius-25",
      icon: "icon-genius font-40px",
      title: "Acompañamiento Estratégico",
      desc:"Colaboramos contigo en cada etapa de la transformación digital para maximizar tu éxito.",
    },
    {
      featureBox: "feature-box text-center xs-mb-30 feature-box-gradient center-feature white-color border-radius-25",
      icon: "icon-dial font-40px",
      title: "Soporte Proactivo",
      desc:"Atención rápida y dedicada para garantizar el funcionamiento continuo de tus servicios.",
    }
  ]

  // Our Process
  steps = [
    
    {
      icon:"icon-tools default-color font-30px",
      title:"Diseño",
      desc:"Adaptación de soluciones en la nube a las necesidades del cliente."
    },
    {
      icon:"icon-globe default-color font-30px",
      title:"Implementación",
      desc:"Despliegue eficiente con mínima interrupción de servicios."
    },
    {
      icon:"icon-mobile default-color font-30px",
      title:"Soporte Continuo",
      desc:"Asistencia proactiva y monitoreo constante."
    },
    {
      icon:"icon-browser default-color font-30px",
      title:"Evolución",
      desc:"Colaboración en la mejora continua y adaptación a nuevas tendencias."
    },
  ]

  // Team
  
  slideStoreTwo : { title: string; subtitle: string; image: string; index: number }[] = [
    {
      title: "Web Designer",
      subtitle: "Tom Bills",
      image: "team-4.jpg",
      index: 0
    },
    {
      title: "CEO of Becki Agency",
      subtitle: "Sara Adams",
      image: "team-5.jpg",
      index: 1
    }
  ];

  socialLinks = [
    { iconOuter: "facebook", iconInner: ['icofont icofont-social-facebook'] },
    { iconOuter: "twitter", iconInner: ['icofont icofont-social-twitter'] },
    { iconOuter: "pinterest", iconInner: ['icofont icofont-social-pinterest'] },
  ];

  // Services
  services = [
    {
      serviceBox: "feature-box text-center xs-mb-30 feature-box-gradient-three center-feature white-color border-radius-25",
      //icon: "icon-cloud font-30px white-color",
      icon: "material-symbols--cloud font-30px white-color",
      title: "Cloud Computing",
      desc: "Despliegue de soluciones híbridas o nativas de la nube con proveedores como AWS, Azure, Google y Oracle, garantizando flexibilidad y escalabilidad para tu negocio.",
    },
    {
      serviceBox: "feature-box text-center xs-mb-30 feature-box-gradient-four center-feature white-color border-radius-25",
      icon: "gravity-ui--cloud-gear font-30px white-color",
      title: "Cloud Automation",
      desc: "Automatización de infraestructura y procesos en la nube. Implementamos soluciones que automatizan la infraestructura y los flujos de trabajo en la nube, reduciendo errores, mejorando la eficiencia y acelerando los tiempos de despliegue y respuesta.",
    },
    {
      serviceBox: "feature-box text-center xs-mb-30 feature-box-gradient-three center-feature white-color border-radius-25",
      icon: "arcticons--daily-dev font-30px white-color",
      title: "DevOps",
      desc: "Integración continua y automatización de operaciones. Ofrecemos servicios DevOps especializados en entornos cloud, integrando la automatización, despliegue continuo y monitorización para garantizar un ciclo de desarrollo ágil y eficiente.",
    },
    {
      serviceBox: "feature-box text-center xs-mb-30 feature-box-gradient-four center-feature white-color border-radius-25",
      icon: "carbon--sales-ops font-30px white-color",
      title: "FinOps",
      desc: "Optimizamos la gestión financiera en la nube alineada con los objetivos financieros y operativos de tu negocio.",
    }
    ,
    {
      serviceBox: "feature-box text-center xs-mb-30 feature-box-gradient-three center-feature white-color border-radius-25",
      icon: "carbon--kubernetes-pod font-30px white-color",
      title: "Contenerización y Orquestación de Aplicaciones",
      desc: "Optimiza el despliegue de aplicaciones mediante la contenerización y orquestación en plataformas como Kubernetes y Docker. Nuestras soluciones aseguran la portabilidad, escalabilidad y gestión eficiente de tus aplicaciones en entornos multicloud o híbridos.",
    },
    {
      serviceBox: "feature-box text-center xs-mb-30 feature-box-gradient-four center-feature white-color border-radius-25",
      icon: "mdi--group-add-outline font-30px white-color",
      title: "Implementación de Plataformas de Colaboración",
      desc: "Facilitamos la adopción e integración de plataformas de colaboración como Microsoft 365 y Google Workspaces, mejorando la productividad y comunicación entre tus equipos. Nuestras soluciones garantizan una transición sin interrupciones y una experiencia optimizada para todos los usuarios.",
    }
  ]

  // Funfacts
  funFacts =[
    {
      icon: "icon-hourglass dark-color font-50px",
      number: 352,
      desc: "Happy Clients",
      displayedNumber: 0
    },
    {
      icon: "icon-lightbulb dark-color font-50px",
      number: 884,
      desc: "Year Experience",
      displayedNumber: 0
    },
    {
      icon: "icon-wine dark-color font-50px",
      number: 662,
      desc: "Project Completed",
      displayedNumber: 0
    },
    {
      icon: "icon-hotairballoon dark-color font-50px",
      number: 444,
      desc: "Awwwards",
      displayedNumber: 0
    }
  ]


  leftServices = [
    {
      title: "Retina Ready",
      desc: "Nunc pharetra ullamcorper enim eget interdum. Morbi vitae pulvinar lacus. Suspendisse pulvinar tempus hendrerit. Suspendisse quam felis.",
    },
    {
      title: "Awesome Design",
      desc: "Nunc pharetra ullamcorper enim eget interdum. Morbi vitae pulvinar lacus. Suspendisse pulvinar tempus hendrerit. Suspendisse quam felis.",
    },
    {
      title: "Minimal Style",
      desc: "Nunc pharetra ullamcorper enim eget interdum. Morbi vitae pulvinar lacus. Suspendisse pulvinar tempus hendrerit. Suspendisse quam felis.",
    }
  ]

  rightServices = [
    {
      title: "Top Quality",
      desc: "Nunc pharetra ullamcorper enim eget interdum. Morbi vitae pulvinar lacus. Suspendisse pulvinar tempus hendrerit. Suspendisse quam felis.",
    },
    {
      title: "Best Performance",
      desc: "Nunc pharetra ullamcorper enim eget interdum. Morbi vitae pulvinar lacus. Suspendisse pulvinar tempus hendrerit. Suspendisse quam felis.",
    },{
      title: "Fast Support",
      desc: "Nunc pharetra ullamcorper enim eget interdum. Morbi vitae pulvinar lacus. Suspendisse pulvinar tempus hendrerit. Suspendisse quam felis.",
    }
  ]

  // Skills
  skills = [
    { name: 'Garantizar la continuidad operativa en la nube.', level: 90 },
    { name: 'Optimizar la infraestructura cloud para mayor agilidad y eficiencia.', level: 80 },
    { name: 'Eliminar prácticas y tecnologías obsoletas, impulsando la modernización.', level: 85 },
    { name: 'Escalabilidad modular y despliegues automatizados con DevOps y DevSecOps.', level: 75 }
  ];

  isScrolled = false;

  @HostListener('window:scroll', [])
  onScroll(): void {
    const skillsSection = document.querySelector('.skill');
    
    // Add a null check for skillsSection
    if (skillsSection) {
      const position = skillsSection.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      // Check if the section is in view
      if (position < windowHeight) {
        this.isScrolled = true;
      }
    }
  }

  // Portfolio
  portfolios = [
    {
      img: "1.jpg",
      title: "CloudShift",
      subTitle: "Migración, Modernización y Optimización Cloud"
    },
    {
      img: "2.jpg",
      title: "Event-Driven Cloud Architecture",
      subTitle: "Serverless, Microservicios y Procesamiento de Eventos en Tiempo Real"
    },
    {
      img: "3.jpg",
      title: "Orquestación de Contenedores en la Nube",
      subTitle: "Escalabilidad y Gestión Automatizada con Kubernetes"
    },
    {
      img: "4.jpg",
      title: "Arquitectura en HA y DRP",
      subTitle: "Alta Disponibilidad y Plan de Recuperación de Desastres"
    },
    {
      img: "5.jpg",
      title: "Monitoreo Centralizado en la Nube",
      subTitle: "Visibilidad Integral Cloud"
    },
    {
      img: "6.jpg",
      title: "IaC",
      subTitle: "Automatización y Escalabilidad"
    },
    {
      img: "7.jpg",
      title: "DataAndAnalytics",
      subTitle: "Insights en Tiempo Real y Escalabilidad"
    },
    {
      img: "8.jpg",
      title: "Serverless",
      subTitle: "Escalabilidad sin Servidores"
    }
  ]

  // Pricing
  pricing = [
    {
      title: "Nuestra Diferencia",      
      btnClass: "btn btn-dark btn-md btn-default btn-square",
      
      features: [
        'Garantizar la continuidad operativa en la nube.',
        'Optimizar la infraestructura cloud para mayor agilidad y eficiencia.',
        'Eliminar prácticas y tecnologías obsoletas, impulsando la modernización.',
        'Escalabilidad modular y despliegues automatizados con DevOps y DevSecOps.',
        'Soporte Proactivo'
      ]
    }
  ];

  // Owl Testimonial
  customOptionsThree: OwlOptions = {
    loop: true,
    dots: true,
    margin:30,
    nav:true,
    autoplay:true,
    autoplaySpeed: 2000,
    navSpeed: 2000,
    items: 3,
    navText: ['<i class="icofont icofont-arrow-left"></i>', '<i class="icofont icofont-arrow-right"></i>'],
    responsive:{
      0:{
          items:1,
      },
      600:{
          items:2,
      },
      1000:{
          items:3,
      }
    }
  }

  slideStoreThree = [
    {
      title: "Anne McAdams",
      subTitle: "CEO / Founder",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sodales nec nulla ac aliquet. Duis vel nunc eget.",
      image: "assets/images/team/avatar-1.jpg",
    },

    {
      title: "Jared Kane",
      subTitle: "CEO / Founder",
      description: "Quisque hendrerit turpis sit amet tortor condimentum, nec commodo lorem condimentum. Sed id diam efficitur, mattis tellus ac, malesuada purus. In ultrices nisl sed eleifend consequat.",
      image: "assets/images/team/avatar-2.jpg",
    },

    {
      title: "Nani Wale",
      subTitle: "CEO / Founder",
      description: "Quisque hendrerit turpis sit amet tortor condimentum, nec commodo lorem condimentum. Sed id diam efficitur, mattis tellus ac, malesuada purus. In ultrices nisl sed eleifend consequat.",
      image: "assets/images/team/avatar-3.jpg",
    },

    {
      title: "John Doe",
      subTitle: "CEO / Founder",
      description: "Quisque hendrerit turpis sit amet tortor condimentum, nec commodo lorem condimentum. Sed id diam efficitur, mattis tellus ac, malesuada purus. In ultrices nisl sed eleifend consequat.",
      image: "assets/images/team/avatar-4.jpg",
    }

  ]

  // Owl Brand Logos
  customOptionsFour: OwlOptions = {
    loop: true,
    dots: false,
    margin:0,
    nav:false,
    autoplay:true,
    autoplaySpeed: 2000,
    navSpeed: 2000,
    items: 6,
    responsive:{
      0:{
          items:1,
      },
      600:{
          items:2,
      },
      1000:{
          items:6,
      }
    }
  }

  slideStoreFour = [
    {
      image: "assets/images/clients/1.png",
    },

    {
      image: "assets/images/clients/2.png",
    },

    {
      image: "assets/images/clients/3.png",
    },

    {
      image: "assets/images/clients/4.png",
    },

    {
      image: "assets/images/clients/5.png",
    },

    {
      image: "assets/images/clients/6.png",
    },

    {
      image: "assets/images/clients/7.png",
    }

  ]



  // Contact Form
  formData = {
    user_name: '',
    user_email: '',
    message: ''
  };

  // EmailJS service configuration
  private serviceId = 'service_57nlbw5';  // Replace with your EmailJS Service ID
  private templateId = 'template_40h3ns2';  // Replace with your EmailJS Template ID
  private publicKey = 'cA1deg0QyW9B8l4u0';  // Replace with your EmailJS Public Key


  // Method to send email on form submission
  public sendEmail(event: Event): void {
    event.preventDefault();

    // Send email using EmailJS
    emailjs.sendForm(this.serviceId, this.templateId, event.target as HTMLFormElement, this.publicKey)
      .then(
        (response: EmailJSResponseStatus) => {
          alert('Message sent successfully. Thank you, we will get back to you soon!');
          this.resetForm();
        },
        (error: EmailJSResponseStatus) => {
          console.error('Failed to send message. Error:', error.text);
          alert('Failed to send the message. Please try again later.');
        }
      );
  }

  // Method to reset the form after submission
  private resetForm(): void {
    this.formData = {
      user_name: '',
      user_email: '',
      message: ''
    };
  }

  socialMedia = [
    {
      iconClass: "fb",
      icon:"icofont icofont-social-facebook",
      link: null
    },
    {
      iconClass: "tw",
      icon:"icon-x-twitter",
      link: null
    },    
    {
      iconClass: "in",
      icon:"icofont icofont-social-linkedin",
      link: 'https://www.linkedin.com/company/k2witsolutions/'
    },
    {
      iconClass: "yt",
      icon:"icofont icofont-social-youtube",
      link: null
    },
    {
      iconClass: "ins",
      icon:"icofont icofont-social-instagram",
      link: null
    }
  ]

  // Video Popup
	isOpen = false;
  openPopup(): void {
      this.isOpen = true;
  }
  closePopup(): void {
      this.isOpen = false;
  }
}
