<nav class="navbar navbar-expand-lg nav-crev" [class.nav-scroll]="isSticky">
    <div class="container">
        <a class="navbar-brand icon-img-100" [routerLink] = "'/'" (click)="$event.preventDefault(); scrollToSection('home')">
            <img [src]="logo" alt="Logo" class="logo">
        </a>
        <button class="navbar-toggler ms-auto" type="button" (click)="isCollapsed = !isCollapsed" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span class="icon-bar"><i class="icofont icofont-navigation-menu"></i></span>
        </button>
        <div class="collapse navbar-collapse" [ngClass]="{'collapse': isCollapsed, 'navbar-collapse': true}" id="navbarNavAltMarkup">
            <ul class="navbar-nav navbar-center xs-mt-20">
                <li class="nav-item" >
                    <a class="nav-link" (click)="scrollToSection('home')" [class.active]="isActive('home')">Inicio</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="scrollToSection('features')" [class.active]="isActive('features')">Caracteristicas</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="scrollToSection('servicios')" [class.active]="isActive('servicios')">Servicios</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="scrollToSection('nosotros')" [class.active]="isActive('nosotros')">Nosotros</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="scrollToSection('portfolio')" [class.active]="isActive('portfolio')">Soluciones</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="scrollToSection('bolsadetrabajo')" [class.active]="isActive('bolsadetrabajo')">Bolsa de trabajo</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="scrollToSection('contact')" [class.active]="isActive('contact')">Contacto</a>
                </li>
                                
            </ul>
            
            <div class="nav-button ms-auto xs-ml-0 xs-mt-20">
                <ul class="nav navbar-nav navbar-right">
                    <li>
                        <button type="button" class="btn btn-gradient-primary btn-circle" style="padding: 10px 20px; border-radius: 25px;">
                            <div style="display: flex; align-items: center;">
                                <i class="icon-whatsapp" style="color: #ffffff; margin-right: 10px; font-size: 1.5rem;"></i>
                                <div style="line-height: 1.4; text-align: left;">
                                    <a href="https://wa.me/525523244356?text=Hola,%20me%20gustaría%20saber%20más%20sobre%20sus%20servicios" target="_blank" style="color: white; text-decoration: none; font-size: 0.85rem; display: block;">
                                        +52 552 324 4356
                                    </a>
                                    <a href="https://wa.me/525531110995?text=Hola,%20me%20gustaría%20saber%20más%20sobre%20sus%20servicios" target="_blank" style="color: white; text-decoration: none; font-size: 0.85rem; display: block;">
                                        +52 553 111 0995
                                    </a>
                                </div>
                            </div>
                        </button>
                    </li>
                </ul>
            </div>
            
            
            
            
        </div>
        
    </div>
</nav>